/**
 * Local development environments
 */
export const environment = {
  url: 'https://zmqpd0syy9.execute-api.us-east-1.amazonaws.com/aero/llm/seguridad',
  profile: 'AM_AV_Dev',
  production: false,
  domain: 'lobbycloudgate.amx-digital.com',
  clientId: 'HKlvQJAmxkH2B9b4c8qwqqLb92NNAa0Q'
};
